import Vue from 'vue'
import Router from 'vue-router'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import store from '@/store'
// Lazy some of the UI
const Login = () => import(/* webpackChunkName: "engine" */ '@/components/Login.vue')
/// Create Business Wizard
const DashboardCreateBusiness = () => import(/* webpackChunkName: "engine" */ '@/components/CreateBusiness.vue')
// Dashboard
const Dashboard = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/Dashboard.vue')
const DashboardHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/Home.vue')
/// Campaigns
const DashboardCampaigns = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/Campaigns.vue')
const DashboardCampaignsHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/Home.vue')
const DashboardCampaignsScheduled = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/Scheduled.vue')
const DashboardCampaignWizard = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/Wizard.vue')
const WizardSectionStart = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/Start.vue')
const WizardSectionCampaignSelect = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/CampaignSelect.vue')
const WizardSectionCampaignWizard = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/CampaignWizard.vue')
const WizardSectionType = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/Type.vue')
const WizardSectionTypeDetails = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/TypeDetails.vue')
const WizardSectionTheme = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/Theme.vue')
const WizardSectionLandingPage = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/LandingPage.vue')
const WizardSectionPostContent = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/PostContent.vue')
const WizardSectionSchedule = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/Schedule.vue')
const WizardSectionConfirmation = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/campaigns/wizard/sections/Confirmation.vue')
/// Performance
const DashboardPerformance = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/performance/Performance.vue')
const DashboardPerformanceHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/performance/Home.vue')
const DashboardPerformanceOrders = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/performance/Orders.vue')
const DashboardPerformanceSubmissions = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/performance/Submissions.vue')
const DashboardPerformanceResults = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/performance/Results.vue')
/// Settings
const DashboardSettings = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/settings/Settings.vue')
const DashboardSettingsHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/settings/Home.vue')
/// Profile
const DashboardProfile = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/profile/Profile.vue')
const DashboardProfileHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/profile/Home.vue')
/// Tours
const DashboardTours = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/tours/Tours.vue')
const DashboardToursWelcome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/tours/Welcome.vue')
/// Super Admin
const DashboardAdmin = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/admin/Admin.vue')
const DashboardAdminHome = () => import(/* webpackChunkName: "engine" */ '@/components/dashboard/admin/Home.vue')

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Router)

function getUser () {
  if (process.env.NODE_ENV === 'development') console.log('running get user')
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.dispatch('fetchUser')
      store.dispatch('fetchAccounts')
      if (process.env.NODE_ENV === 'development') console.log('setting with actual user')
      return data
    }
  }).catch((e) => {
    if (process.env.NODE_ENV === 'development') console.log('null user set', e)
    return null
  })
}

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'route-home', redirect: { name: 'route-login' } },
    { path: '/login', name: 'route-login', component: Login, meta: { title: 'Login' } },
    { path: '/engine',
      name: 'route-engine',
      component: Dashboard,
      meta: { requiresAuth: true, title: 'Dashboard' },
      redirect: { name: 'route-engine-home' },
      children: [
        { path: 'home',
          name: 'route-engine-home',
          component: DashboardHome,
          meta: { title: 'Home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'home')
            next()
          }
        },
        { path: 'campaigns',
          name: 'route-engine-campaigns',
          component: DashboardCampaigns,
          meta: { title: 'Campaigns' },
          redirect: { name: 'route-engine-campaigns-home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'campaigns')
            next()
          },
          children: [
            { path: '/', name: 'route-engine-campaigns-home', component: DashboardCampaignsHome, meta: { title: 'Campaigns' } },
            { path: 'wizard',
              name: 'route-engine-campaigns-wizard',
              component: DashboardCampaignWizard,
              meta: { title: 'Wizard' },
              redirect: { name: 'route-engine-campaigns-start' },
              children: [
                { path: 'start', name: 'route-engine-campaigns-start', component: WizardSectionStart, meta: { title: 'Wizard : Start' } },
                { path: 'campaign-select', name: 'route-engine-campaigns-campaign-select', component: WizardSectionCampaignSelect, meta: { title: 'Wizard : Campaign Select' } },
                { path: 'campaign-wizard', name: 'route-engine-campaigns-wizard-campaign-wizard', component: WizardSectionCampaignWizard, meta: { title: 'Wizard : Campaign Details' } },
                { path: 'type', name: 'route-engine-campaigns-wizard-type', component: WizardSectionType, meta: { title: 'Wizard : Campaign Type' } },
                { path: 'type-details', name: 'route-engine-campaigns-wizard-type-details', component: WizardSectionTypeDetails, meta: { title: 'Wizard : Type Details' } },
                { path: 'theme', name: 'route-engine-campaigns-wizard-theme', component: WizardSectionTheme, meta: { title: 'Wizard : Theme' } },
                { path: 'landing-page', name: 'route-engine-campaigns-wizard-landing-page', component: WizardSectionLandingPage, meta: { title: 'Wizard : Landing Page' } },
                { path: 'post-content', name: 'route-engine-campaigns-wizard-post-content', component: WizardSectionPostContent, meta: { title: 'Wizard : Post Details' } },
                { path: 'schedule', name: 'route-engine-campaigns-wizard-schedule', component: WizardSectionSchedule, meta: { title: 'Wizard : Schedule' } },
                { path: 'confirmation', name: 'route-engine-campaigns-wizard-confirmation', component: WizardSectionConfirmation, meta: { title: 'Wizard : Confirmation' } }
              ]
            },
            { path: 'scheduled', name: 'route-engine-campaigns-scheduled', component: DashboardCampaignsScheduled, meta: { title: 'Scheduled Campaigns' } }
          ]
        },
        { path: 'performance',
          name: 'route-engine-performance',
          component: DashboardPerformance,
          meta: { title: 'Performance' },
          redirect: { name: 'route-engine-performance-home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'performance')
            next()
          },
          children: [
            { path: '/', name: 'route-engine-performance-home', component: DashboardPerformanceHome, meta: { title: 'Performance : Home' } },
            { path: 'orders', name: 'route-engine-performance-orders', component: DashboardPerformanceOrders, meta: { title: 'Performance : Orders' } },
            { path: 'orders/:campaignId', name: 'route-engine-performance-orders-w-id', component: DashboardPerformanceOrders, meta: { title: 'Performance : Orders' } },
            { path: 'results/:campaignId', name: 'route-engine-performance-results', component: DashboardPerformanceResults, meta: { title: 'Performance : Results' } },
            { path: 'submissions/:campaignId', name: 'route-engine-performance-submissions', component: DashboardPerformanceSubmissions, meta: { title: 'Performance : Submissions' } },
            { path: ':campaignId', name: 'route-engine-performance-home-w-id', component: DashboardPerformanceHome, meta: { title: 'Performance : Home' } }
          ]
        },
        { path: 'settings',
          name: 'route-engine-settings',
          component: DashboardSettings,
          meta: { title: 'Settings' },
          redirect: { name: 'route-engine-settings-home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'settings')
            next()
          },
          children: [
            { path: '/', name: 'route-engine-settings-home', component: DashboardSettingsHome, meta: { title: 'Settings' } }
          ]
        },
        { path: 'profile',
          name: 'route-engine-profile',
          component: DashboardProfile,
          meta: { title: 'My Profile' },
          redirect: { name: 'route-engine-profile-home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'profile')
            next()
          },
          children: [
            { path: '/', name: 'route-engine-profile-home', component: DashboardProfileHome }
          ]
        },
        { path: 'admin',
          name: 'route-engine-admin',
          component: DashboardAdmin,
          meta: { title: 'Super Admin' },
          redirect: { name: 'route-engine-admin-home' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'profile')
            next()
          },
          children: [
            { path: '/', name: 'route-engine-admin-home', component: DashboardAdminHome }
          ]
        },
        { path: 'tours',
          name: 'route-engine-tours',
          component: DashboardTours,
          meta: { title: 'Tours' },
          redirect: { name: 'route-engine-tours-welcome' },
          beforeEnter (to, from, next) {
            store.dispatch('setSection', 'tours')
            next()
          },
          children: [
            {
              path: 'welcome',
              name: 'route-engine-tours-welcome',
              component: DashboardToursWelcome,
              meta: { title: 'Tours : Welcome' }
            }
          ]
        }
      ]
    },
    { path: '/create-business',
      name: 'route-create-business',
      component: DashboardCreateBusiness,
      meta: { requiresAuth: true, title: 'Create Business' },
      beforeEnter (to, from, next) {
        store.dispatch('setSection', 'create-business')
        next()
      }
    },
    { path: '/logout',
      name: 'route-logout',
      beforeEnter (to, from, next) {
        Vue.prototype.$Amplify.Auth.signOut({ global: true })
          .then(data => {
            if (process.env.NODE_ENV === 'development') console.log('log out success', data)
            next({ name: 'route-login' })
          })
          .catch(err => {
            if (process.env.NODE_ENV === 'development') console.log('log out failure', err)
            next({ name: 'route-login' })
          })
      }
    },
    { path: '*', redirect: '/' }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeResolve(async (to, from, next) => {
  // GA4 manual pageview (assure history change is DISABLED in stream settings!)
  // if here assures that we dont double count the first and only automatic pageview triggered when GA4 is loaded on public src HTML
  if (from.name) window.gtag('event', 'page_view', { page_title: to.meta.title, page_location: to.path })
  window.Intercom('update') // refresh the intercom messenger
  store.dispatch('setMenuState', { menu: 'main', state: false }) // always close the menu on a route change
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (process.env.NODE_ENV === 'development') console.log('secure route ... checking')
    var user = await getUser()
    if (!user) {
      if (process.env.NODE_ENV === 'development') console.log('dont have a user, kicking out to login!')
      return next({
        name: 'route-login',
        query: { redirect: to.fullPath }
      })
    }
    if (process.env.NODE_ENV === 'development') console.log('we have an authd user, sending to: ', to.fullPath)
    return next()
  }
  if (process.env.NODE_ENV === 'development') console.log('not a secure path, sending to:', to.fullPath)
  return next()
})

export default router
