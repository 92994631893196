import engineApi from '@/plugins/engine-api.js'
import Vue from 'vue'

let vm = new Vue()

const getDefaultState = () => {
  return {
    fatal: false,
    user: null,
    accounts: null,
    account: null,
    channels: null,
    saveAccountId: null,
    embedMode: null
  }
}

const state = getDefaultState()

const getters = {
  fatal: state => state.fatal,
  currentUser: state => state.user,
  currentAccounts: state => state.accounts,
  currentAccount: state => state.account,
  currentAccountChannels: state => state.channels,
  currentSaveAccountId: state => state.saveAccountId,
  currentEmbedMode: state => state.embedMode
}

const actions = {
  fetchUser ({ commit }) {
    engineApi.fetchUser(function (err, user) {
      if (err) {
        if (process.env.NODE_ENV === 'development') console.log(err)
        commit('SET_FATAL', true)
      } else {
        // set Google Analytics userId
        // if (process.env.NODE_ENV !== 'development') window.ga('set', 'userId', user['cognito_id'])
        commit('SET_USER', user)
      }
    })
  },
  resetUser ({ commit }) {
    commit('SET_USER', { email: null })
  },
  fetchAccounts ({ dispatch, commit, state }) {
    engineApi.fetchAccounts(function (err, accounts) {
      if (err) {
        if (process.env.NODE_ENV === 'development') console.log(err)
        commit('SET_FATAL', true)
      } else {
        commit('SET_ACCOUNTS', accounts)
        if (accounts.length > 0) {
          if (!state.account) { // we don't have an account selected
            var setId = accounts[0].id // default to first account in list
            var lastId = vm.$cookie.get('lastAccountId') // fetch the last account cookie
            if (lastId) {
              lastId = parseInt(lastId)
              if (accounts.find(x => x.id === lastId)) { // lastId is in list of account
                setId = lastId
              }
            }
            dispatch('setAccount', setId)
          }
        } else {
          // we dont have any accounts for this user, this is fatal
          commit('SET_FATAL', true)
        }
      }
    })
  },
  fetchChannels ({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      engineApi.fetchChannels(accountId,
        function (err, channels) {
          if (err) {
            commit('SET_CHANNELS', null)
            resolve()
          } else {
            commit('SET_CHANNELS', channels)
            resolve()
          }
        }
      )
    })
  },
  setAccount ({ dispatch, commit }, accountId) {
    engineApi.fetchAccount(accountId, function (err, acc) {
      if (err) {
        if (process.env.NODE_ENV === 'development') console.log(err)
        commit('SET_FATAL', true)
      } else {
        if (acc) {
          commit('SET_ACCOUNT', acc)
          vm.$cookie.set('lastAccountId', accountId)
          // when we set account, also go and refresh the channels
          dispatch('fetchChannels', accountId)
        } else {
          // we dont have an account, this is fatal
          commit('SET_FATAL', true)
        }
      }
    })
  },
  setSaveAccountId ({ commit }, accountId) {
    commit('SET_SAVE_ACCOUNT_ID', accountId)
  },
  setFatal ({ commit }, fatal) {
    commit('SET_FATAL', fatal)
  },
  resetUserState ({ commit }) {
    commit('RESET_USER_STATE')
  },
  setEmbedMode ({ commit }, embedMode) {
    commit('SET_EMBED_MODE', embedMode)
  }
}

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_ACCOUNTS (state, data) {
    state.accounts = data
  },
  SET_ACCOUNT (state, account) {
    state.account = account
  },
  SET_CHANNELS (state, data) {
    state.channels = data
  },
  SET_SAVE_ACCOUNT_ID (state, accountId) {
    state.saveAccountId = accountId
  },
  SET_FATAL (state, fatal) {
    state.fatal = fatal
  },
  SET_EMBED_MODE (state, embedMode) {
    state.embedMode = embedMode
  },
  RESET_USER_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
