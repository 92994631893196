const getDefaultState = () => {
  return {
    filter: null,
    daysBack: null,
    dateRange: null
  }
}

const state = getDefaultState()

const getters = {
  currentPerformanceFilter: state => state.filter,
  currentPerformanceDaysBack: state => state.daysBack,
  currentPerformanceDateRange: state => state.dateRange
}

const actions = {
  setPerformanceFilter ({ commit }, filter) {
    commit('SET_PERFORMANCE_FILTER', filter)
  },
  setPerformanceDaysBack ({ commit }, daysBack) {
    commit('SET_PERFORMANCE_DAYS_BACK', daysBack)
  },
  setPerformanceDateRange ({ commit }, range) {
    commit('SET_PERFORMANCE_DATE_RANGE', range)
  },
  resetPerformanceState ({ commit }) {
    commit('RESET_PERFORMANCE_STATE')
  }
}

const mutations = {
  SET_PERFORMANCE_FILTER (state, filter) {
    state.filter = filter
  },
  SET_PERFORMANCE_DAYS_BACK (state, daysBack) {
    state.daysBack = daysBack
  },
  SET_PERFORMANCE_DATE_RANGE (state, range) {
    state.dateRange = range
  },
  RESET_PERFORMANCE_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
