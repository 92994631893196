const getDefaultState = () => {
  return {
    section: null,
    loading: false,
    guideMode: false,
    guideModeId: null,
    guideModeLastWM: null,
    notify: {
      state: false,
      text: '',
      color: null,
      url: null,
      noAutoClose: false
    },
    menuStates: {
      main: false,
      user: false,
      account: false,
      help: false
    }
  }
}

const state = getDefaultState()

const getters = {
  currentMenuStates: state => state.menuStates,
  currentNotify: state => state.notify,
  currentLoading: state => state.loading,
  currentGuideMode: state => state.guideMode,
  currentGuideModeId: state => state.guideModeId,
  currentGuideModeLastWM: state => state.guideModeLastWM,
  currentSection: state => state.section
}

const actions = {
  setMenuState ({ commit }, states) {
    commit('SET_MENU_STATE', states)
  },
  setNotify ({ commit }, notify) {
    commit('SET_NOTIFY', notify)
  },
  setLoadingState ({ commit }, loading) {
    commit('SET_LOADING_STATE', loading)
  },
  setGuideMode ({ commit }, mode) {
    commit('SET_GUIDE_MODE', mode)
  },
  setGuideModeId ({ commit }, id) {
    commit('SET_GUIDE_MODE_ID', id)
  },
  setGuideModeLastWM ({ commit }, wm) {
    commit('SET_GUIDE_MODE_LAST_WM', wm)
  },
  setSection ({ commit }, section) {
    commit('SET_SECTION', section)
  },
  resetUiState ({ commit }) {
    commit('RESET_UI_STATE')
  }
}

const mutations = {
  SET_MENU_STATE (state, states) {
    // close every menu first
    for (var prop in state.menuStates) {
      state.menuStates[prop] = false
    }
    state.menuStates[states.menu] = states.state
  },
  SET_NOTIFY (state, notify) {
    state.notify.state = notify.state
    state.notify.text = notify.text
    state.notify.color = notify.color
    state.notify.url = notify.url
    state.notify.noAutoClose = notify.noAutoClose
  },
  SET_LOADING_STATE (state, loading) {
    state.loading = loading
  },
  SET_GUIDE_MODE (state, mode) {
    state.guideMode = mode
  },
  SET_GUIDE_MODE_ID (state, id) {
    state.guideModeId = id
  },
  SET_GUIDE_MODE_LAST_WM (state, wm) {
    state.guideModeLastWM = wm
  },
  SET_SECTION (state, section) {
    state.section = section
  },
  RESET_UI_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
