import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ui from './modules/ui'
import wizard from './modules/wizard'
import user from './modules/user'
import performance from './modules/performance'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui,
    wizard,
    user,
    performance
  },
  plugins: [createPersistedState()],
  strict: true
})
