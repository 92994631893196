import { version } from '../../package.json'
import axios from 'axios'
import Vue from 'vue'

function getToken () {
  return Vue.prototype.$Amplify.Auth.currentSession().then((data) => {
    if (data) {
      return data.accessToken.jwtToken
    }
  }).catch((e) => {
    return null
  })
}

export default {
  fetchUser (callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/user', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(user => {
          callback(null, user)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchAccount (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/account', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'account_id': accountId } })
        .then(r => r.data)
        .then(account => {
          callback(null, account)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchAccounts (callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/accounts', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(accounts => {
          callback(null, accounts)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchAccountUsers (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/accountusers', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'account_id': accountId } })
        .then(r => r.data)
        .then(users => {
          callback(null, users)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchChannels (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/channels', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'account_id': accountId } })
        .then(r => r.data)
        .then(channels => {
          callback(null, channels)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchCampaign (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (campaignId) {
        headers['id'] = campaignId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/campaign', { 'headers': headers })
        .then(r => r.data)
        .then(campaign => {
          callback(null, campaign)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchEmailHTML (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (campaignId) {
        headers['id'] = campaignId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/campaignemail', { 'headers': headers })
        .then(r => r.data)
        .then(html => {
          var finalHTML = null
          // html is passed back as a buffer, convert it back to HTML string
          if (typeof html === 'object') {
            const buf = Buffer.from(html)
            if (buf) finalHTML = buf.toString()
          }
          callback(null, finalHTML)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchCampaigns (accountId, tag, page, search, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (tag) {
        headers['id'] = tag
      }
      if (page) {
        headers['page'] = page
      }
      if (search && typeof search === 'object') {
        var querystring = require('querystring')
        headers['qs'] = querystring.encode(search)
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/campaigns', { 'headers': headers })
        .then(r => r.data)
        .then(campaigns => {
          callback(null, campaigns)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchMetricTable (mode, accountId, page, numberOfResults, daysBack, search, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': mode
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (page) {
        headers['page'] = page
      }
      if (numberOfResults) {
        headers['limit'] = numberOfResults
      }
      if (daysBack) {
        headers['date_span'] = daysBack
      }
      if (search && typeof search === 'object') {
        var querystring = require('querystring')
        headers['qs'] = querystring.encode(search)
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/metrictable', { 'headers': headers })
        .then(table => {
          callback(null, table.data)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchOrders (mode, accountId, page, numberOfResults, daysBack, search, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': mode
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (page) {
        headers['page'] = page
      }
      if (numberOfResults) {
        headers['limit'] = numberOfResults
      }
      if (daysBack) {
        headers['date_span'] = daysBack
      }
      if (search && typeof search === 'object') {
        var querystring = require('querystring')
        headers['qs'] = querystring.encode(search)
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/orders', { 'headers': headers })
        .then(orders => {
          if (orders.headers['content-type'] !== 'application/json') {
            callback(null, orders) // return the entire response if this isn't JSON
          } else {
            callback(null, orders.data) // we're just returning the JSON, this is how every other fetch works
          }
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchOrder (accountId, slug, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (slug) {
        headers['id'] = slug
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/order', { 'headers': headers })
        .then(r => r.data)
        .then(order => {
          callback(null, order)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchImages (accountId, mode, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/images', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'account_id': accountId, 'mode': mode } })
        .then(r => r.data)
        .then(images => {
          callback(null, images)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchTags (type, mode, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (type) {
        headers['type'] = type
      }
      if (mode) {
        headers['mode'] = mode
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/tags', { 'headers': headers })
        .then(r => r.data)
        .then(tags => {
          callback(null, tags)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchThemes (tag, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (tag) {
        headers['id'] = tag
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/themes', { 'headers': headers })
        .then(r => r.data)
        .then(themes => {
          callback(null, themes)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchSwatches (callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/swatches', { 'headers': headers })
        .then(r => r.data)
        .then(swatches => {
          callback(null, swatches)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchStatistics (accountId, type, daysBack, search, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'type': type,
        'account_id': accountId
      }
      if (daysBack) {
        headers['date_span'] = daysBack
      }
      if (search && typeof search === 'object') {
        var querystring = require('querystring')
        headers['qs'] = querystring.encode(search)
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/statistics', { 'headers': headers })
        .then(r => r.data)
        .then(statistics => {
          callback(null, statistics)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchSubmissions (accountId, campaignId, timezone, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'id': campaignId,
        'account_id': accountId,
        'mode': timezone
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/submissions', { 'headers': headers })
        .then(r => r.data)
        .then(submissions => {
          callback(null, submissions)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchNotifications (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/notifications', { 'headers': headers })
        .then(r => r.data)
        .then(notifications => {
          callback(null, notifications)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchBanners (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/banner', { 'headers': headers })
        .then(r => r.data)
        .then(banners => {
          callback(null, banners)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  fetchChannelsUser (type, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.get(process.env.VUE_APP_GATEWAY_URL + '/app/channelsuser', { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'type': type } })
        .then(r => r.data)
        .then(channels => {
          callback(null, channels)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveChannel (accountId, type, channelId, name, key, secret, payload, active, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'type': type,
        'active': active
      }
      if (channelId) headers['channel_id'] = channelId
      if (name) headers['name'] = name
      if (key) headers['access_token'] = key
      if (secret) headers['token_secret'] = secret
      axios.put(process.env.VUE_APP_GATEWAY_URL + '/app/channel', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveAccount (type, payload, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.put(process.env.VUE_APP_GATEWAY_URL + '/app/account', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'type': type } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveCampaign (accountId, campaignId, type, subtype, actionsubtype, title, description, postText, actionText, colors, action, layout, theme, post, channels, sendDate, sendTime, status, otherVariables, unsplash, campaign, account, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        accountId: accountId,
        campaignId: campaignId,
        version: version,
        type: type,
        subtype: subtype,
        actionsubtype: actionsubtype,
        title: title,
        description: description,
        postText: postText,
        actionText: actionText,
        colors: colors,
        action: action,
        layout: layout,
        theme: theme,
        variables: otherVariables,
        unsplash: unsplash,
        post: post,
        channels: channels,
        sendDate: sendDate,
        sendTime: sendTime,
        status: status,
        campaign: campaign,
        account: account
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/campaign', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  editCampaign (accountId, campaignId, title, description, actionText, colors, action, layout, theme, actionsubtype, otherVariables, campaign, account, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        accountId: accountId,
        campaignId: campaignId,
        title: title,
        description: description,
        actionsubtype: actionsubtype,
        actionText: actionText,
        colors: colors,
        action: action,
        layout: layout,
        theme: theme,
        variables: otherVariables,
        campaign: campaign,
        account: account
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/campaign', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveUser (name, email, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (name) {
        headers['name'] = name
      }
      if (email) {
        headers['email'] = email
      }
      axios.put(process.env.VUE_APP_GATEWAY_URL + '/app/user', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  createAccount (name, email, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        name: name,
        email: email
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/createaccount', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  createBusiness (payload, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/account', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  joinBusiness (payload, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/joinrequest', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  createUser (name, email, password, accountId, callback) {
    // password - optional, accountId - optional
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        name: name,
        email: email,
        password: password,
        accountId: accountId
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/createuser', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveNotification (accountId, recipient, type, trigger, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      var payload = {
        recipient: recipient,
        type: type,
        trigger: trigger
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/notification', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveBannerImage (image, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var mode = 'image'
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/banner', image, { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'mode': mode } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  saveBannerHTML (html, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var mode = 'html'
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': mode
      }
      var payload = {
        html: html
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/banner', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  toggleCampaign (accountId, campaignId, type, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (campaignId) {
        headers['id'] = campaignId
      }
      if (type) {
        headers['type'] = type
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/togglecampaign', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  cinchToggle (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/cinchtoggle', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  toggleFeed (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (campaignId) {
        headers['id'] = campaignId
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/togglefeed', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  toggleAccountUser (accountId, userId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'id': userId
      }
      axios.put(process.env.VUE_APP_GATEWAY_URL + '/app/accountuser', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  toggleOrder (accountId, slug, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': 'accounttoggle'
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (slug) {
        headers['id'] = slug
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/order', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  addAccountUser (accountId, email, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      var payload = {
        email: email
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/accountuser', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  deleteCampaign (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (campaignId) {
        headers['id'] = campaignId
      }
      axios.delete(process.env.VUE_APP_GATEWAY_URL + '/app/campaign', { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  deleteImage (accountId, imageId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (imageId) {
        headers['id'] = imageId
      }
      axios.delete(process.env.VUE_APP_GATEWAY_URL + '/app/image', { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  deleteNotification (accountId, recipient, type, trigger, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      var payload = {
        recipient: recipient,
        type: type,
        trigger: trigger
      }
      axios.delete(process.env.VUE_APP_GATEWAY_URL + '/app/notification', { 'data': payload, 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  deleteAccountUser (accountId, userId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId,
        'id': userId
      }
      axios.delete(process.env.VUE_APP_GATEWAY_URL + '/app/accountuser', { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  deleteChannel (accountId, type, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken
      }
      if (accountId) {
        headers['account_id'] = accountId
      }
      if (type) {
        headers['type'] = type
      }
      axios.delete(process.env.VUE_APP_GATEWAY_URL + '/app/channel', { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  uploadImage (accountId, image, mode, type, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/image', image, { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken, 'account_id': accountId, 'mode': mode, 'type': type } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  generateAssets (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        accountId: accountId,
        campaignId: campaignId,
        version: version
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/genasset', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  generateSummary (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        accountId: accountId,
        campaignId: campaignId,
        version: version
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/gensummary', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  triggerChannels (trigger, accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        trigger: trigger,
        accountId: accountId,
        campaignId: campaignId,
        version: version
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/triggerchannel', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  triggerAggregateBuild (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        accountId: accountId,
        version: version
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/triggeraggregate', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  drawImage (payload, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/drawimage', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  refreshImpressions (accountId, campaignId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'account_id': accountId
      }
      var payload = {
        campaignId: campaignId
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/refreshimpressions', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  masterGoogle (accountId, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var payload = {
        account_id: accountId
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/mastergoogle', JSON.stringify(payload), { 'headers': { 'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY, 'state': cognitoAccessToken } })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  addCampaignTemplate (jsonForS3, otherDetails, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': 'add'
      }
      var payload = {
        'jsonForS3': jsonForS3,
        'otherDetails': otherDetails
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/campaigntemplate', JSON.stringify(payload), { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  toggleCampaignTemplate (id, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': 'toggle',
        'id': id
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/campaigntemplate', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  },
  orderCampaignTemplate (id, order, callback) {
    getToken().then(function (token) {
      var cognitoAccessToken = token
      var headers = {
        'x-api-key': process.env.VUE_APP_GATEWAY_API_KEY,
        'state': cognitoAccessToken,
        'mode': 'order',
        'id': id,
        'type': order
      }
      axios.post(process.env.VUE_APP_GATEWAY_URL + '/app/campaigntemplate', null, { 'headers': headers })
        .then(r => r.data)
        .then(response => {
          callback(null, response)
        }).catch(error => {
          callback(error, null)
        })
    }).catch(function (err) {
      if (process.env.NODE_ENV === 'development') console.log('error getting token', err)
      callback(err, null)
    })
  }
}
