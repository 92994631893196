const getDefaultState = () => {
  return {
    saveWarning: false,
    saveOverride: false,
    editPublishedMode: false,
    dismissedPublishedModeWarning: false,
    editId: null,
    wizardType: null,
    wizardSubtype: null,
    wizardStartMode: null,
    wizardStep: null,
    wizardTitle: null,
    wizardDetails: null,
    wizardAction: null,
    wizardTheme: null,
    wizardLandingPage: null,
    wizardPostContent: null,
    wizardSchedule: null,
    wizardSlug: null,
    wizardAssets: null,
    wizardPostRender: null,
    wizardUnsplashImages: null,
    wizardSuggestedForm: null,
    campaignRichContent: null,
    saveWarningMode: null
  }
}

const state = getDefaultState()

const getters = {
  saveWarning: state => state.saveWarning,
  saveOverride: state => state.saveOverride,
  editId: state => state.editId,
  editPublishedMode: state => state.editPublishedMode,
  dismissedPublishedModeWarning: state => state.dismissedPublishedModeWarning,
  wizardType: state => state.wizardType,
  wizardSubtype: state => state.wizardSubtype,
  wizardStartMode: state => state.wizardStartMode,
  wizardStep: state => state.wizardStep,
  wizardTitle: state => state.wizardTitle,
  wizardDetails: state => state.wizardDetails,
  wizardAction: state => state.wizardAction,
  wizardTheme: state => state.wizardTheme,
  wizardLandingPage: state => state.wizardLandingPage,
  wizardPostContent: state => state.wizardPostContent,
  wizardSchedule: state => state.wizardSchedule,
  wizardSlug: state => state.wizardSlug,
  wizardAssets: state => state.wizardAssets,
  wizardPostRender: state => state.wizardPostRender,
  wizardUnsplashImages: state => state.wizardUnsplashImages,
  wizardSuggestedForm: state => state.wizardSuggestedForm,
  campaignRichContent: state => state.campaignRichContent,
  saveWarningMode: state => state.saveWarningMode,
  fullWizard: state => state
}

const actions = {
  setSaveWarning ({ commit }, warning) {
    commit('SAVE_WARNING', warning)
  },
  setSaveOverride ({ commit }, value) {
    commit('SET_SAVE_OVERRIDE', value)
  },
  saveEditId ({ commit }, value) {
    commit('SAVE_EDIT_ID', value)
  },
  setEditPublishedMode ({ commit }) {
    commit('SET_EDIT_PUBLISHED_MODE')
  },
  setDismissedPublishedModeWarning ({ commit }) {
    commit('SET_DISMISSED_PUBLISHED_MODE_WARNING')
  },
  saveSection ({ commit }, section) {
    commit('SAVE_SECTION', section)
  },
  setWizardStep ({ commit }, step) {
    commit('SET_WIZARD_STEP', step)
  },
  setWizardType ({ commit }, type) {
    commit('SET_WIZARD_TYPE', type)
  },
  setWizardSubtype ({ commit }, subtype) {
    commit('SET_WIZARD_SUBTYPE', subtype)
  },
  setWizardStartMode ({ commit }, mode) {
    commit('SET_WIZARD_STARTMODE', mode)
  },
  setWizardTitle ({ commit }, title) {
    commit('SET_WIZARD_TITLE', title)
  },
  setWizardSlug ({ commit }, slug) {
    commit('SET_WIZARD_SLUG', slug)
  },
  setWizardAssets ({ commit }, assets) {
    commit('SET_WIZARD_ASSETS', assets)
  },
  setWizardPostRender ({ commit }, variables) {
    commit('SET_WIZARD_POST_RENDER', variables)
  },
  setWizardUnsplashImages ({ commit }, images) {
    commit('SET_WIZARD_UNSPLASH_IMAGES', images)
  },
  setWizardSuggestedForm ({ commit }, form) {
    commit('SET_WIZARD_SUGGESTED_FORM', form)
  },
  setCampaignRichContent ({ commit }, content) {
    commit('SET_CAMPAIGN_RICH_CONTENT', content)
  },
  setSaveWarningMode ({ commit }, mode) {
    commit('SET_SAVE_WARNING_MODE', mode)
  },
  resetWizardState ({ commit }) {
    commit('RESET_WIZARD_STATE')
  },
  injectWizard ({ dispatch, commit }, wizard) {
    commit('INJECT_WIZARD', wizard)
  }
}

const mutations = {
  SAVE_WARNING (state, warning) {
    state.saveWarning = warning
  },
  SET_SAVE_OVERRIDE (state, value) {
    state.saveOverride = value
  },
  SAVE_EDIT_ID (state, value) {
    state.editId = value
  },
  SET_EDIT_PUBLISHED_MODE (state) {
    state.editPublishedMode = true
  },
  SET_DISMISSED_PUBLISHED_MODE_WARNING (state) {
    state.dismissedPublishedModeWarning = true
  },
  SAVE_SECTION (state, section) {
    if (section.name && section.subkey) {
      if (state[section.name]) {
        if (state[section.name][section.subkey]) {
          state[section.name][section.subkey] = section.value
        }
      }
    } else {
      state[section.name] = section.value
    }
  },
  SET_WIZARD_STEP (state, step) {
    state.wizardStep = step
  },
  SET_WIZARD_TYPE (state, type) {
    state.wizardType = type
  },
  SET_WIZARD_SUBTYPE (state, subtype) {
    state.wizardSubtype = subtype
  },
  SET_WIZARD_STARTMODE (state, mode) {
    state.wizardStartMode = mode
  },
  SET_WIZARD_TITLE (state, title) {
    state.wizardTitle = title
  },
  SET_WIZARD_SLUG (state, slug) {
    state.wizardSlug = slug
  },
  SET_WIZARD_ASSETS (state, assets) {
    state.wizardAssets = assets
  },
  SET_WIZARD_POST_RENDER (state, variables) {
    state.wizardPostRender = variables
  },
  SET_WIZARD_UNSPLASH_IMAGES (state, images) {
    state.wizardUnsplashImages = images
  },
  SET_WIZARD_SUGGESTED_FORM (state, form) {
    state.wizardSuggestedForm = form
  },
  SET_CAMPAIGN_RICH_CONTENT (state, content) {
    if (content !== undefined) state.campaignRichContent = content
  },
  SET_SAVE_WARNING_MODE (state, mode) {
    state.saveWarningMode = mode
  },
  RESET_WIZARD_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  INJECT_WIZARD (state, wizard) {
    state.wizardStep = 1
    state.wizardType = null
    state.wizardPostRender = null
    state.wizardUnsplashImages = null
    state.wizardSubtype = wizard.wizardSubtype
    state.wizardTitle = wizard.wizardTitle
    state.wizardDetails = wizard.wizardDetails
    state.wizardAction = wizard.wizardAction
    state.wizardTheme = wizard.wizardTheme
    state.wizardLandingPage = wizard.wizardLandingPage
    state.wizardPostContent = wizard.wizardPostContent
    state.wizardSchedule = wizard.wizardSchedule
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
